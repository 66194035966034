<template>
  <div
    class="word-cloud-container"
    :class="[
      reportFullscreen.status ? 'change-height' : '',
      isCollapsed & this.reportFullscreen.status ? 'change-width' : ''
    ]">
    <div class="header" v-if="chartTitle">
      <div>
        {{ chartTitle }}
        <img
          src="../../assets/info-new.svg"
          alt=""
          @mouseenter="tooltip($event, chartInfo, 'in')"
          @mouseleave="tooltip($event, chartInfo, 'out')"
          @mousemove="tooltip($event, chartInfo, 'in')"
          class="pl-2" />
      </div>
      <b-button class="toggle-button" @click="toggleReportFullscreen(id)">
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" class="expand-icon" width="16" height="16" fill="#c2c7de" viewBox="0 0 16 16" v-if="!fullscreen" v-bind:class="'icons'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M2.328 10.181H0V16h5.819v-2.328H2.328zM0 5.819h2.328V2.328h3.491V0H0zM10.181 0v2.328h3.491v3.491H16V0zM13.672 13.672h-3.491V16H16v-5.819h-2.328z" class="cls-1"/></svg>
          <svg xmlns="http://www.w3.org/2000/svg" fill="#c2c7de" width="16" height="16" viewBox="0 0 16 16" v-else="" v-bind:svg-inline="''" v-bind:class="'icons'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3.491 16h2.328v-5.819H0v2.328h3.491zM5.819 0H3.491v3.491H0v2.328h5.819zM16 5.819V3.491h-3.491V0h-2.328v5.819zM12.509 12.509H16v-2.328h-5.819V16h2.328z" class="cls-1"/></svg>
        </div>
      </b-button>
    </div>
    <div v-if="!noData">
        <div class="wordcloud-legend border" v-if="type === 'emerging'">
          <div class="legend">
            <span
              class="colored-rect"
              style="background: var(--word-cloud-emerging-color)"></span>
            <p
              style="color: var(--word-cloud-emerging-color)"
              title="Emerging topics represents clusters of most discussed keywords and metadata (tags) based on direct citation and co-citation to represent current interest of reviewers.">
              Emerging Topics
            </p>
          </div>
        </div>
      <svg :id="cloudId"></svg>
        <div class="wordcloud-legend border mb-3" v-if="type === 'strength'">
          <div class="legend">
            <span
              class="colored-rect"
              style="background: var(--word-cloud-emerging-color)"></span>
            <p>Strength</p>
          </div>
          <div class="legend">
            <span
              class="colored-rect"
              style="background: var(--word-cloud-text-color)"></span>
            <p>Weakness</p>
          </div>
        </div>
      </div>
    <div class="noDataContainer" v-else>
      <no-data-container-vue type="sunset-sunrise" v-if="noData" />
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3'
// import cloud from './d3.layout.cloud'
import cloud from 'd3-cloud'
import { mapActions, mapState } from 'vuex'
import NoDataContainerVue from '../../components/Common/NoDataContainer/NoDataContainer.vue'
import tooltip from '../../util/tooltip'
import { fullScreenMode, debounce } from '../../util/util'

/**
 * This component shows the keywords that have been talked about the most in the selected time period, compared to the ones that had been talked about the most in the previous period. Clicking on a word in this chart will redirect the user to the *Comments* page with filters automatically applied to only show comments tagged under that keyword.
 */
export default {
  name: 'Wordcloud',
  components: {
    NoDataContainerVue
  },
  props: {
    /**
     * The id for this element
     */
    cloudId: {
      type: String,
      default: null
    },
    /**
     * The data for this plot, in the form of an array of objects
     */
    wordData: {
      type: Array,
      default: null
    },
    /** The header/title for the chart */
    chartTitle: {
      type: String,
      default: null
    },
    /**
     * The information displayed in the default tooltip when a cursor is hovered over the *i* icon in the header
     */
    chartInfo: {
      type: String,
      default: null
    },
    /** Identifies which page this word cloud is being generated on, and what properties need to be applied to it.
     * @values 'emerging', 'strength'
     */
    type: {
      type: String,
      default: null
    },
    /**
     * If specified, sets the height of the chart, otherwise the chart assumes the height of its parent element.
     */
    chartHeight: {
      type: Number
    },
    /**
     * The upper limit of the font-size for words in the cloud.
     */
    maxFont: {
      type: Number,
      default: 40
    }
  },
  data () {
    return {
      id: 2,
      observer: null,
      noData: false
    }
  },
  computed: {
    ...mapState('sunriseSunset', ['featuredTopicsData', 'selectedSSTopic', 'selectedVoeSSTopic', 'selectedAttribute']),
    ...mapState('winLoss', [
      'wlSelectedProductLeft',
      'wlSelectedProductRight',
      'wlLeftStrengths',
      'wlLeftWeaknesses',
      'wlRightStrengths',
      'wlRightWeaknesses'
    ]),
    ...mapState('common', ['isCollapsed', 'reportFullscreen', 'currentModule']),
    // selectedTopic () {
    //   if (this.currentModule === 'voc') {
    //     return this.selectedSSTopic
    //   } else {
    //     return this.selectedVoeSSTopic
    //   }
    // },
    fullscreen () {
      return (
        this.reportFullscreen.id === this.id && this.reportFullscreen.status
      )
    }
  },
  watch: {
    wordData () {
      if (this.wordData.length) {
        this.noData = false
        setTimeout(this.renderChart, 10)
      } else {
        this.noData = true
      }
    },
    // featuredTopicsData () {
    //   if (this.featuredTopicsData.length) {
    //     this.noData = false
    //     setTimeout(this.renderChart, 10)
    //   } else {
    //     this.noData = true
    //   }
    // },
    'reportFullscreen.status' () {
      this.renderChart()
    }
  },
  mounted () {
    this.renderChart()
    this.resizeWindow()
  },
  beforeDestroy () {
    if (this.observer) this.observer.disconnect()
  },
  methods: {
    ...mapActions('common', ['toggleReportFullscreen']),
    ...mapActions('sunriseSunset', [
      'updatecustomSortedAttributes',
      'updateSelectedAttribute'
    ]),
    ...mapActions('filters', ['updateSelectedFeatures', 'updateSelectedProducts']),
    ...mapActions('sunriseSunset', ['updatePolarSentimentAttributesOnly']),
    tooltip,
    /** This methods limits the number of times that *resizeWindow()* is called. Improves performance for the sidepanel collapsation/expansion
    * @public
    */
    debounceEvent: debounce(function (e) {
      this.renderChart()
    }, 200),
    /**
     * This method utilizes a resizeObserver to dynamically adjust the width of the chart when the width of it's parent element changes
     * @public
     */
    resizeWindow () {
      this.observer = new ResizeObserver(() => {
        if (d3.select('.word-cloud-container').node() != null) {
          // console.log('Resizing')
          // this.renderChart()
          this.debounceEvent()
        }
      })
      this.observer.observe(document.querySelector('.word-cloud-container'))
    },
    tooltipFun (event, d, type) {
      let data = {}
      switch (type) {
        case 'in':
          if (d.chartType === 'strength') {
            const category = d.category === 'emerging' ? 'Strength' : 'Weakness'
            data = {
              // '': d.category === 'emerging' ? 'Strength' : 'Weakness',
              '# of comments': d.commentCount // d.value.toFixed(2)
            }
          } else {
            data = {
              '# of comments (current period)': d.currentCount,
              '# of comments (previous period)': d.previousCount,
              'Change in share': d.percentagediff.toFixed(2) + '%'
            }
          }
          break
      }
      tooltip(event, data, type)
    },
    /**
     * This method is used to render the WordCloud
     * @public
     */
    renderChart () {
      // d3.layout.cloud = cloud()
      // List of words
      const that = this
      let myWords = []
      myWords = this.wordData
      // set the dimensions and margins of the graph
      d3.selectAll('#' + this.cloudId + ' > *').remove()

      // eslint-disable-next-line no-unused-vars
      const minDomain = d3.min(myWords.map((d) => d.size))
      const maxDomain = d3.max(myWords.map((d) => d.size))

      const margin = { top: 0, right: 10, bottom: 0, left: 10 }
      const legendHeight = this.type === 'emerging' ? 80 : 45
      // const width = d3.select('.word-cloud-container').node().getBoundingClientRect().width
      const width =
        d3.select('.word-cloud-container').node().getBoundingClientRect()
          .width -
        margin.left -
        margin.right
      const height =
        this.chartHeight ||
        d3.select('.word-cloud-container').node().getBoundingClientRect()
          .height -
        margin.top -
        margin.bottom -
        legendHeight
      // console.log(d3.select('.dot-plot-container').node().getBoundingClientRect().height)
      // const width = 700 - margin.left - margin.right
      // const height = 250 - margin.top - margin.bottom - 30

      // append the svg object to the body of the page
      const svg = d3
        .select('#' + this.cloudId) // .append('svg')
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
        .append('g')
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')

      // const categories = d3.keys(d3.nest().key(function (d) { return d.category }).map(data))
      // const color = d3.scaleOrdinal().range(['#3C90F5', '#4D586B', '#66c2a5', '#fc8d62', '#8da0cb', '#e78ac3', '#a6d854'])
      // eslint-disable-next-line no-unused-vars
      const color = d3.scaleOrdinal().range(['#3C90F5', '#4D586B'])
      // const fontSize = d3.scalePow().exponent(5).domain([0, 1]).range([10, 80])
      const fontSize = d3
        .scaleLinear()
        .domain([0, maxDomain])
        .range([8, that.maxFont])

      // Constructs a new cloud layout instance. It run an algorithm to find the position of words that suits your requirements
      // Wordcloud features that are different from one word to the other must be here
      const layout = cloud()
        .size([width, height])
        .words(extractor(that.type))
        .padding(5) // space between words
        // .rotate(function () { return ~~(Math.random() * 2) * 90 })
        .rotate(function (d) {
          return 0
        })
        .spiral('rectangular')
        .font('Impact')
        .fontSize((d) => {
          // console.log(d.text, fontSize(d.size))
          return fontSize(d.size)
        }) // font size of words
        .on('end', draw)
      layout.start()

      function extractor (type) {
        switch (type) {
          case 'emerging':
            return myWords.map(function (d) {
              return {
                text: d.word,
                size: d.size,
                percentagediff: d.percentagediff,
                category: d.percentagediff > 0 ? 'emerging' : ' declining',
                commentCount: d.new_c,
                currentCount: d.new_c,
                previousCount: d.old_c,
                keywords: d.keywords,
                chartType: that.type
              }
            })
          case 'strength':
            return myWords.map(function (d) {
              return {
                text: d.word,
                size: d.size,
                category: d.category,
                chartType: that.type,
                value: d.size,
                keywords: d.keywords,
                commentCount: d.comment_count,
                wlPosition: d.wlPosition
              }
            })
          default:
            break
        }
      }

      // This function takes the output of 'layout' above and draw the words
      // Wordcloud features that are THE SAME from one word to the other can be here
      function draw (words) {
        svg
          .append('g')
          .attr(
            'transform',
            'translate(' +
              layout.size()[0] / 2 +
              ',' +
              layout.size()[1] / 2 +
              ')'
          )
          .selectAll('text')
          .data(words)
          .enter()
          .append('text')
          .style('font-size', function (d) {
            return d.size
          })
          .style('fill', function (d) {
            // return color(d.category)
            if (d.category === 'emerging') {
              return 'var(--word-cloud-emerging-color)'
            } else {
              return 'var(--word-cloud-text-color)'
            }
          })
          .style('font-weight', 'bold')
          .attr('text-anchor', 'middle')
          .style('font-family', 'Quicksand')
          .attr('transform', function (d) {
            return 'translate(' + [d.x, d.y] + ')rotate(' + d.rotate + ')'
          })
          .text(function (d) {
            return d.text
          })
          .style('cursor', 'pointer')
          .on('click', function (event, d) {
            d.word = d.text
            // console.log(d)
            that.updateSelectedAttribute([d]) // pass as [d]
            // that.updatecustomSortedAttributes(that.featuredTopicsData.sort(function (x, y) { return x.word === d.text ? -1 : y.word === d.text ? 1 : 0 }))
            // that.updatecustomSortedAttributes(that.featuredTopicsData.sort(function (x, y) { return parseInt(y.new_c) - parseInt(x.new_c) }))
            that.tooltipFun(event, d, 'out', that.type)
            if (that.reportFullscreen.status) {
              that.toggleReportFullscreen()
              fullScreenMode(document, 'off')
            }
            if (that.selectedSSTopic) {
              const t = {
                id: that.selectedSSTopic.id,
                is_standard: that.selectedSSTopic.is_standard,
                name: that.selectedSSTopic.name,
                parent_id: that.selectedSSTopic.parent_id
              }
              const arr = []
              arr.push(t)
              that.updateSelectedFeatures(arr)
              that.updatePolarSentimentAttributesOnly(false)
            }
            if (d.wlPosition === 'left' && that.currentModule === 'voc') {
              that.updatePolarSentimentAttributesOnly(true)
              that.updateSelectedProducts([that.wlSelectedProductLeft])
              // if (d.category === 'strength') {
              const arr = []
              that.wlLeftStrengths.forEach((t) => {
                const obj = {
                  id: t.id,
                  is_standard: t.is_standard,
                  name: t.topic,
                  parent_id: t.parent_id
                }
                arr.push(obj)
              })
              // console.log(arr)
              // that.updateSelectedFeatures(arr)
              // } else {
              // const arr = new Array()
              that.wlLeftWeaknesses.forEach((t) => {
                const obj = {
                  id: t.id,
                  is_standard: t.is_standard,
                  name: t.topic,
                  parent_id: t.parent_id
                }
                arr.push(obj)
              })
              // console.log(arr)
              that.updateSelectedFeatures(arr)
              // }
            }
            if (d.wlPosition === 'right' && that.currentModule === 'voc') {
              that.updatePolarSentimentAttributesOnly(true)
              that.updateSelectedProducts([that.wlSelectedProductRight])
              // if (d.category === 'strength') {
              const arr = []
              that.wlRightStrengths.forEach((t) => {
                const obj = {
                  id: t.id,
                  is_standard: t.is_standard,
                  name: t.topic,
                  parent_id: t.parent_id
                }
                arr.push(obj)
              })
              // console.log(arr)
              //   that.updateSelectedFeatures(arr)
              // } else {
              //   const arr = new Array()
              that.wlRightWeaknesses.forEach((t) => {
                const obj = {
                  id: t.id,
                  is_standard: t.is_standard,
                  name: t.topic,
                  parent_id: t.parent_id
                }
                arr.push(obj)
              })
              // console.log(arr)
              that.updateSelectedFeatures(arr)
              // }
            }
            if (that.currentModule === 'voc') {
              that.$router.push({ name: 'comments' })
            }
            if (that.currentModule === 'voe') {
              that.$router.push({ name: 'voeComments' })
            }
          })
          .on('mouseover', (event, d) => {
            return that.tooltipFun(event, d, 'in')
          })
          .on('mousemove', (event, d) => {
            return that.tooltipFun(event, d, 'in')
          })
          .on('mouseout', (event, d) => {
            return that.tooltipFun(event, d, 'out')
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.word-cloud-container {
  overflow: hidden;
  padding: 5px;
  .noDataContainer {
    height: 90%;
  }
  //  display: block;
  // svg {
  //   width: 100%;
  //   height: 250px;
  // }
  transition: width 0.3s ease-out;
  height: 350px;
  min-height: 350px;
  .header {
    color: var(--trending-declining-header);
    opacity: 1;
    font-size: 18px;
    font-weight: 500;
    flex: 1;
    font-family: Quicksand;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 36px;
    padding: 0px 10px;
    // padding-top: 15px;
    // padding-bottom: 5px;
    .toggle-button {
      // margin-left: auto;
      // margin-right: 20px;
      background-color: transparent;
      outline: none;
      border: none;
      padding: 2px 3px;
      display: flex;
      div {
        display: flex;
      }
    }
  }
  .wordcloud-legend {
    display: flex;
    justify-content: center;
    width: fit-content;
    margin: 0 auto;
      // margin-bottom: 25px;
    .legend {
      display: inline-flex;
      margin: 5px 10px;
      color: var(--dotplot-legend-text-color);
      font-size: 12px;
      font-family: Quicksand;
      .colored-rect {
        display: inline-flex;
        width: 8px;
        height: 8px;
        border-radius: 8px;
        margin-right: 3px;
        align-self: center;
      }
    }
  }
  .border {
    border: 1px solid #C7C7C7
  }
}

.word-cloud-container.change-height {
  height: calc(100vh - 25px - var(--page-tracking-height));
  width: calc(100vw - 269px);
}
.word-cloud-container.change-width {
  width: calc(100vw - 104px);
}

@media screen and (min-width: 1700px) {
  .word-cloud-container.change-height {
    width: calc(100vw - 285px);
    }
  .word-cloud-container.change-width {
    width: calc(100vw - 120px)
  }
}
</style>
