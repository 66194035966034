var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"word-cloud-container",class:[
    _vm.reportFullscreen.status ? 'change-height' : '',
    _vm.isCollapsed & this.reportFullscreen.status ? 'change-width' : ''
  ]},[(_vm.chartTitle)?_c('div',{staticClass:"header"},[_c('div',[_vm._v(" "+_vm._s(_vm.chartTitle)+" "),_c('img',{staticClass:"pl-2",attrs:{"src":require("../../assets/info-new.svg"),"alt":""},on:{"mouseenter":function($event){return _vm.tooltip($event, _vm.chartInfo, 'in')},"mouseleave":function($event){return _vm.tooltip($event, _vm.chartInfo, 'out')},"mousemove":function($event){return _vm.tooltip($event, _vm.chartInfo, 'in')}}})]),_c('b-button',{staticClass:"toggle-button",on:{"click":function($event){return _vm.toggleReportFullscreen(_vm.id)}}},[_c('div',[(!_vm.fullscreen)?_c('svg',{staticClass:"expand-icon",class:'icons',attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"16","fill":"#c2c7de","viewBox":"0 0 16 16","svg-inline":'',"role":'presentation',"focusable":'false',"tabindex":'-1'}},[_c('path',{staticClass:"cls-1",attrs:{"d":"M2.328 10.181H0V16h5.819v-2.328H2.328zM0 5.819h2.328V2.328h3.491V0H0zM10.181 0v2.328h3.491v3.491H16V0zM13.672 13.672h-3.491V16H16v-5.819h-2.328z"}})]):_c('svg',{class:'icons',attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"#c2c7de","width":"16","height":"16","viewBox":"0 0 16 16","svg-inline":'',"role":'presentation',"focusable":'false',"tabindex":'-1'}},[_c('path',{staticClass:"cls-1",attrs:{"d":"M3.491 16h2.328v-5.819H0v2.328h3.491zM5.819 0H3.491v3.491H0v2.328h5.819zM16 5.819V3.491h-3.491V0h-2.328v5.819zM12.509 12.509H16v-2.328h-5.819V16h2.328z"}})])])])],1):_vm._e(),(!_vm.noData)?_c('div',[(_vm.type === 'emerging')?_c('div',{staticClass:"wordcloud-legend border"},[_vm._m(0)]):_vm._e(),_c('svg',{attrs:{"id":_vm.cloudId}}),(_vm.type === 'strength')?_c('div',{staticClass:"wordcloud-legend border mb-3"},[_vm._m(1),_vm._m(2)]):_vm._e()]):_c('div',{staticClass:"noDataContainer"},[(_vm.noData)?_c('no-data-container-vue',{attrs:{"type":"sunset-sunrise"}}):_vm._e()],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"legend"},[_c('span',{staticClass:"colored-rect",staticStyle:{"background":"var(--word-cloud-emerging-color)"}}),_c('p',{staticStyle:{"color":"var(--word-cloud-emerging-color)"},attrs:{"title":"Emerging topics represents clusters of most discussed keywords and metadata (tags) based on direct citation and co-citation to represent current interest of reviewers."}},[_vm._v(" Emerging Topics ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"legend"},[_c('span',{staticClass:"colored-rect",staticStyle:{"background":"var(--word-cloud-emerging-color)"}}),_c('p',[_vm._v("Strength")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"legend"},[_c('span',{staticClass:"colored-rect",staticStyle:{"background":"var(--word-cloud-text-color)"}}),_c('p',[_vm._v("Weakness")])])
}]

export { render, staticRenderFns }