import { render, staticRenderFns } from "./WordCloud.vue?vue&type=template&id=01a29446&scoped=true&"
import script from "./WordCloud.vue?vue&type=script&lang=js&"
export * from "./WordCloud.vue?vue&type=script&lang=js&"
import style0 from "./WordCloud.vue?vue&type=style&index=0&id=01a29446&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01a29446",
  null
  
)

export default component.exports